'use strict';

(function () {
  class ViewTurbineCtrl {
    constructor(appConfig, $scope, $state, $http, $window, Turbine, Blade, Auth, Util, Finding, Modal,
      $cookieStore, $cookies, $timeout, $uibModal, toastr, ActivityLog, SharedUrl, $sce, usSpinnerService, Report) {
      this.$scope = $scope;
      this.Turbine = Turbine;
      this.Blade = Blade;
      this.usSpinnerService = usSpinnerService;
      this.Modal = Modal;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.isAdmin = Auth.isAdmin;
      this.toastr = toastr;
      this.appConfig = appConfig;
      this.API_KEY = appConfig.GOOGLE_API_KEY;
      this.getAddress = Util.getAddress;
      this.$http = $http;
      this.$state = $state;
      this.$uibModal = $uibModal;
      this.Util = Util;
      this.mapImageName = '';
      this.riskAssessment = '';
      this.ActivityLog = ActivityLog;
      this.SharedUrl = SharedUrl;
      this.Report = Report;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.$sce = $sce;
      this.bladePositionMap = {
      	'A': 1,
      	'B': 2,
      	'C': 3
      };
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
          this.init();
        } else {
          $state.go('login');
        }
      });
      let w = angular.element($window);

      $scope.getWindowDimensions = function () {
        return {
          'h': w.height(),
          'w': w.width()
        };
      };

      this.totalCatCount = this.$scope.turbine.findingsCount.cat1
        + this.$scope.turbine.findingsCount.cat2 + this.$scope.turbine.findingsCount.cat3
        + this.$scope.turbine.findingsCount.cat4 + this.$scope.turbine.findingsCount.cat5;
      // console.log('this.$scope.turbine: ', this.$scope.turbine);
      // for top page in print
      this.$scope.siteOverview = {
        reportNumber: Math.round(Date.now() / 1000),
        siteName: this.$scope.turbine.site.name,
        siteLocation: this.Util.getAddressForReport(this.$scope.turbine.site.location),
        sitePosition: this.$scope.turbine.sitePosition,
        turbineSerialNo: this.$scope.turbine.serial,
        turbineBlades: this.$scope.turbine.blades,
        turbineMake: this.$scope.turbine.make,
        turbineModel: this.$scope.turbine.model,
        completionDate: new Date(this.$scope.turbine.createdAt),
        reportDate: new Date()
      };

      this.$scope.turbineStr = '';
      if(this.$scope.turbine.make) {
        this.$scope.turbineStr+=this.$scope.turbine.make
      }
      if(this.$scope.turbine.model) {
        this.$scope.turbineStr+=this.$scope.turbine.model
      }
      if(this.$scope.turbine.name) {
        this.$scope.turbineStr+=this.$scope.turbine.name
      }

      $scope.$on('findingsReceived', (event, findings) => {
        this.enablePrintBtn = true;
        if (findings.length > 0) {
          let mostRecentActivityDate = new Date(this.$scope.turbine.createdAt);
          _.each(findings, finding => {
            if(finding.repairs) {
              _.each(finding.repairs, repair => {
                if(repair.date && mostRecentActivityDate.getTime() < new Date(repair.date).getTime()) {
                  mostRecentActivityDate = new Date(repair.date);
                } else if(!repair.date && mostRecentActivityDate.getTime() < new Date(repair.updatedAt).getTime()) {
                  mostRecentActivityDate = new Date(repair.updatedAt);
                }
              });
            }
            if(finding.date && mostRecentActivityDate.getTime() < new Date(finding.date).getTime()) {
              mostRecentActivityDate = new Date(finding.date);
            } else if(!finding.date && mostRecentActivityDate.getTime() < new Date(finding.updatedAt).getTime()) {
              mostRecentActivityDate = new Date(finding.updatedAt);
            }
          });
          if(mostRecentActivityDate) {
            this.setMostRecentActivityDate(mostRecentActivityDate);
          }
          
        }
      });

      $scope.$watch($scope.getWindowDimensions, (newValue, oldValue) => {
        if (newValue.w < 768) {
          this.graphWidth = newValue.w - 40;
          this.graphHeight = 250;
        } else if (newValue.w >= 768 && newValue.w <= 992) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 992 && newValue.w <= 1200) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 1200 && newValue.w <= 1360) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else {
          this.graphWidth = 212;
          this.graphHeight = 250;
        }

        /* Chart options */
        this.options = {
          chart: {
            type: 'pieChart',
            width: this.graphWidth,
            height: this.graphHeight,
            donut: true,
            title: this.totalCatCount,
            x: function (d) {
              return d.key;
            },
            y: function (d) {
              return d.y;
            },
            showLegend: true,
            showLabels: true,
            showValues: true,
            valueFormat: function (d) {
              return d3.format(',.0f')(d);
            },
            labelType: function (d) {
              return d.value
            },
            color: function (d, i) {
              return (d && d.color);
            },
            duration: 500
          }
        };
      }, true);

      w.bind('resize', function () {
        $scope.$apply();
      });
    }

    setMostRecentActivityDate(date) {
      if(this.$scope.siteOverview.completionDate.getTime() < new Date(date).getTime()) {
        this.$scope.siteOverview.completionDate = new Date(date);;
      }
    }

    init() {
      if (!(this.$scope.turbine && this.$scope.turbine.findingsCount)) {
        return;
      }
      if (this.$scope.turbine && this.$scope.turbine.findingsCount.cat1 >= 0
        && this.$scope.turbine.findingsCount.cat2 >= 0 && this.$scope.turbine.findingsCount.cat3 >= 0
        && this.$scope.turbine.findingsCount.cat4 >= 0 && this.$scope.turbine.findingsCount.cat5 >= 0) {

        this.$scope.turbine.totalFindingsCount = this.$scope.turbine.findingsCount.cat1 +
          this.$scope.turbine.findingsCount.cat2 + this.$scope.turbine.findingsCount.cat3 +
          this.$scope.turbine.findingsCount.cat4 + this.$scope.turbine.findingsCount.cat5;
      }

      this.graphData = [{
        key: "Cat 1 : Low - No action required",
        y: this.$scope.turbine.findingsCount.cat1,
        color: this.appConfig.colors.cat1
      }, {
        key: "Cat 2 : Medium - Monitor during inspection schedule/repair if repairing other findings",
        y: this.$scope.turbine.findingsCount.cat2,
        color: this.appConfig.colors.cat2
      }, {
        key: "Cat 3 : Serious - Reinspect/Repair within 6 months",
        y: this.$scope.turbine.findingsCount.cat3,
        color: this.appConfig.colors.cat3
      }, {
        key: "Cat 4 : Major - Reinspect/Repair within 3 months",
        y: this.$scope.turbine.findingsCount.cat4,
        color: this.appConfig.colors.cat4
      }, {
        key: "Cat 5 : Critical - Critical - STOP turbine investigate/repair/replace immediately",
        y: this.$scope.turbine.findingsCount.cat5,
        color: this.appConfig.colors.cat5
      }];

      this.getHighestColorImage();
      this.getBladesCount();
    }

    getBladesCount() {
      let bladeAData = _.filter(this.$scope.turbine.blades, (blade) => {
        return blade.position == 'A' || blade.position == 1;
      });
      let bladeBData = _.filter(this.$scope.turbine.blades, (blade) => {
        return blade.position == 'B' || blade.position == 2;
      });
      let bladeCData = _.filter(this.$scope.turbine.blades, (blade) => {
        return blade.position == 'C' || blade.position == 3;
      });
      this.bladeCount = [];
      this.bladeCount[1] = bladeAData.length;
      this.bladeCount[2] = bladeBData.length;
      this.bladeCount[3] = bladeCData.length;
    }

    archiveTurbine() {
      this.Modal.confirm.archive((turbine) => {
        // console.log('turbine archived', turbine);
        this.Turbine.archive({ id: turbine._id }, { oldSiteId: turbine.site._id }).$promise
          .then(turbine => {
            this.toastr.success('Turbine archived successfully.');
            const activityLogData = {
              site: this.$scope.turbine.site._id,
              turbine: this.$scope.turbine._id,
              user: this.currentUser._id,
              source: 'web',
              action: 'archive',
              type: 'turbine',
              sourceScreen: 'turbine',
              eventString: `Turbine <a href="/turbine/${this.$scope.turbine._id}">${this.$scope.turbineStr}</a> was archived in wind farm <a href="/site/${this.$scope.turbine.site._id}">${this.$scope.turbine.site.name}</a> by ${this.currentUser.name}`
            }
            this.ActivityLog.save(activityLogData).$promise
              .then(activityLog => {
                console.log(activityLog);
              })
              .catch(err => {
                console.log('Error inserting activityLog', err);
            });
            this.$state.go('main');
          })
          .catch(err => {
            console.log(err);
            this.error = err;
            this.toastr.error('Something went wrong. please try later or contact administrator.');
          });
      })((this.$scope.turbine.name || this.$scope.turbine.make || '') + '(' + this.$scope.turbine.model + ')', this.$scope.turbine);
    }

    deleteTurbine() {
      this.Modal.confirm.delete((turbine) => {
        // console.log('turbine archived', turbine);
        this.Turbine.delete({ id: turbine._id }).$promise
          .then(turbine => {
            this.toastr.success('Turbine deleted successfully.');
            this.$state.go('main');
          })
          .catch(err => {
            console.log(err);
            this.error = err;
            if(err && err.data && err.data.meta && err.data.meta.error_message) {
              this.toastr.error(err.data.meta.error_message);
            } else {
              this.toastr.error('Something went wrong. please try later or contact administrator.');
            }
          });
      })((this.$scope.turbine.name || this.$scope.turbine.make || '') + '(' + this.$scope.turbine.model + ')', this.$scope.turbine);
    }

    exportFindings() {
      this.$http({
        method: 'GET',
        url: "/api/v1/findings/export/turbine/" + this.$scope.turbine._id + '?turbineName=' + this.$scope.turbine.name + '&turbineSerial=' + this.$scope.turbine.serial,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
        .success((data, status, headers, config) => {
          // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
          saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), this.$scope.turbine.serial + '_turbine.xlsx');

          const activityLogData = {
            site: this.$scope.turbine.site._id,
            turbine: this.$scope.turbine._id,
            user: this.currentUser._id,
            source: 'web',
            action: 'export findings',
            type: 'turbine',
            sourceScreen: 'turbine',
            eventString: `Findings for Turbine <a href="/turbine/${this.$scope.turbine._id}">${this.$scope.turbineStr}</a> was exported in wind farm <a href="/site/${this.$scope.turbine.site._id}">${this.$scope.turbine.site.name}</a> by ${this.currentUser.name}`
          }
          this.ActivityLog.save(activityLogData).$promise
            .then(activityLog => {
              console.log(activityLog);
            })
            .catch(err => {
              console.log('Error inserting activityLog', err);
          });

        })
        .error((data, status, headers, config) => {
          console.log(err);
          this.turbine = null;
        });
    }

    exportRepairs() {
      this.$http({
        method: 'GET',
        url: "/api/v1/repairs/export/turbine/" + this.$scope.turbine._id + '?turbineName=' + this.$scope.turbine.name + '&turbineSerial=' + this.$scope.turbine.serial,
        // This is important! Tell it to expect an arraybuffer
        responseType: "arraybuffer"
      })
        .success((data, status, headers, config) => {
          // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
          saveAs(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), this.$scope.turbine.serial + '_turbine.xlsx');

          const activityLogData = {
            site: this.$scope.turbine.site._id,
            turbine: this.$scope.turbine._id,
            user: this.currentUser._id,
            source: 'web',
            action: 'export repairss',
            type: 'turbine',
            sourceScreen: 'turbine',
            eventString: `Repairs for Turbine <a href="/turbine/${this.$scope.turbine._id}">${this.$scope.turbineStr}</a> was exported in wind farm <a href="/site/${this.$scope.turbine.site._id}">${this.$scope.turbine.site.name}</a> by ${this.currentUser.name}`
          }
          this.ActivityLog.save(activityLogData).$promise
            .then(activityLog => {
              console.log(activityLog);
            })
            .catch(err => {
              console.log('Error inserting activityLog', err);
          });

        })
        .error((data, status, headers, config) => {
          console.log(err);
          this.turbine = null;
        });
    }

    getHighestColorImage() {
      // console.log('scope.turbine: ', this.$scope.turbine);
      // console.log('getHighestColorImage finding: ', this.$scope.turbine.findingsCount.turbineCategory[this.$scope.turbine._id]);
      // console.log('max category: ', _.maxBy(findings, 'category'));
      var finding = this.$scope.turbine.findingsCount.turbineCategory[this.$scope.turbine._id];
      if (finding) {
        const findingClasses = this.Util.getFindingClass(finding);
        this.mapImageName = _.findKey(findingClasses, function (value, key) {
          // console.log(value, key);
          return findingClasses[key] === true;
        });
        this.riskAssessment = this.Util.getDefectRecommendation(finding.category);
        console.log('findingClass: ', this.mapImageName);
      }
    }

    openFullImage(url) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-full-image/viewFullImageModal.html',
        controller: 'ViewFullImageModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          imageUrl: function () { return url }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    print() {
      this.$scope.$broadcast('showAllFindingImages');
      var tempTitle = document.title;
      document.title = `Windmanager - ${this.$scope.turbine.site.name} ${this.$scope.turbine.serial} - ${moment().format('D MMM YYYY')}.pdf`;
      window.print();
      document.title = tempTitle;

      const activityLogData = {
        site: this.$scope.turbine.site._id,
        turbine: this.$scope.turbine._id,
        user: this.currentUser._id,
        source: 'web',
        action: 'print',
        type: 'turbine',
        sourceScreen: 'turbine',
        eventString: `Turbine <a href="/turbine/${this.$scope.turbine._id}">${this.$scope.turbineStr}</a> was printed in wind farm <a href="/site/${this.$scope.turbine.site._id}">${this.$scope.turbine.site.name}</a> by ${this.currentUser.name}`
      }
      this.ActivityLog.save(activityLogData).$promise
        .then(activityLog => {
          console.log(activityLog);
        })
        .catch(err => {
          console.log('Error inserting activityLog', err);
      });
    }

    edit() {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/turbine/addEditTurbineModal/addEditTurbineModal.html',
        controller: 'AddEditTurbineModalCtrl',
        controllerAs: 'aETurbine',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Edit Turbine',
            site: this.$scope.turbine.site,
            turbine: this.$scope.turbine
          }
        }
      });
      modalInstance.result.then(() => {
        this.$scope.$emit('updateTurbine');
        this.init();
      });
    }

    shareUrl() {
      const shareUrlData = {
        url: `turbine/${this.$scope.turbine._id}`,
        level: 'turbine',
        organization: this.currentUser.organization._id,
      }
      var url = window.location.href;
      var urlArray = url.split("/");
      var baseUrl = urlArray[0] + "//" + urlArray[2];
      this.SharedUrl.save(shareUrlData).$promise
        .then(sharedUrlResponse => {
          this.urlShared = `${baseUrl}/shared/${sharedUrlResponse.data._id}`;
          this.htmlPopover = this.$sce.trustAsHtml(`${this.urlShared} <i>Users without an account will see a read-only version of this page.</i>`);
          this.showShareUrlPopup = true;
          var dummyElement = document.createElement("textarea");
          document.body.appendChild(dummyElement);
          dummyElement.value = this.urlShared;
          dummyElement.select();
          document.execCommand("copy");
          document.body.removeChild(dummyElement);
          this.toastr.success('Link copied successfully.');
        })
        .catch(err => {
          console.log('Error inserting sharedUrl', err);
      });
    }

    mergeBlades() {
      let selectedBlades = _.filter(this.$scope.turbine.blades, { checked: true });
      if (!(selectedBlades && selectedBlades.length > 1)) {
        console.log('please select at least two blades.');
        this.toastr.error('please select at least two blades.');
        return;
      }
      let firstSelectedBladePosition = selectedBlades[0].position;
      let firstSelectedBladeCount = _.filter(selectedBlades, (blade) => {
        return blade.position == firstSelectedBladePosition || blade.position == this.bladePositionMap[firstSelectedBladePosition];
      });
      if(firstSelectedBladeCount.length !== selectedBlades.length) {
        console.log('please select same position blades.');
        this.toastr.error('please select same position blades.');
        return;
      }
      console.log('checked correctly');
      this.Modal.confirm.show(success => {
        this.startSpin('spinner-2');
        this.Blade.merge({}, { turbie: this.$scope.turbine._id, bladeIds: _.map(selectedBlades, blade => blade._id) }).$promise
        .then(blade => {
          this.stopSpin('spinner-2');
          this.toastr.success('Baldes merged successfully.');
          this.$state.reload();
        })
        .catch(err => {
          this.stopSpin('spinner-2');
          console.log(err);
          this.error = err;
          this.toastr.error('Something went wrong. please try later or contact administrator.');
        });
      },'Confirm Merge', `<p>Are your sure you want to merge ${selectedBlades.length} blades.</p>`);
    }

    createReport(reportType) {
      this.Modal.confirm.confirmCreateReport(success => {
        console.log('yes');
        let turbineData = {
          _id: this.$scope.turbine.site._id,
          turbines: [this.$scope.turbine]
        };
        const addReportData = {
          _id: this.$scope.turbine.site._id,
          site: turbineData,
          turbines: [{'_id': this.$scope.turbine._id}],
          type: reportType,
          generatePdfForEachTurbine: true,
          createdBy: this.currentUser._id
        }
        console.log('addReportData', addReportData);
        this.Report.addReport(addReportData).$promise
        .then(report => {
          this.toastr.success('Report request submitted.');
        })
        .catch(err => {
          console.log(err);
          this.error = err;
          this.toastr.error('Something went wrong. please try later or contact administrator.');
        });
      },'Create Report', `<p>A report will be emailed to ${this.currentUser.email}. Proceed?</p>`);
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('ViewTurbineCtrl', ViewTurbineCtrl);
})();
